import * as inputTypes from './inputType';

export const questions = {
  gender: { questionName: 'gender', label: 'Non binary' },
};

export const presetQuestions = [
  {
    label: 'Sports club',
    presetQuestionName: 'club',
    type: inputTypes.text,
  },
  {
    label: 'ID or Passport number',
    presetQuestionName: 'idNumber',
    type: inputTypes.text,
  },
  {
    label: 'Target time',
    presetQuestionName: 'targets',
    type: inputTypes.text,
  },
  {
    label: 'T-shirt size',
    presetQuestionName: 'size',
    extraOptionsLabel: 'Please select available T-shirt sizes',
    values: ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
    type: inputTypes.dropdown,
  },
  {
    label: 'Phone',
    presetQuestionName: 'phone',
    type: inputTypes.text,
  },
  {
    label: 'Emergency contact',
    presetQuestionName: 'ice',
    extraOptionsLabel: 'Request ICE relationship?',
    values: ['Yes'],
    type: inputTypes.none,
  },
  {
    label: 'Address',
    presetQuestionName: 'address',
    type: inputTypes.text,
  },
];

export const extraInfo = {
  ice: {
    name: '',
    relationship: '',
    code: '',
    phone: '',
  },
  address: {
    address: '',
    zip: '',
    city: '',
    countryCode: '',
  },
  phone: {
    code: '',
    phone: '',
  },
  size: '',
};

export const questionOrder = presetQuestions.map((q) => q.presetQuestionName);
