import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { flatMapDeep } from 'lodash';
import { useApi, methods } from '../../../../Hooks/useApi';
import { orderUrl } from '../../../../Hooks/useOrders';
import { Context } from '../../../../Stores/EventInfoStore';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import TicketSelect from '../TicketSelect';
import AddOrderManuallyInfo from './AddOrderManuallyInfo';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import WrappedDivider from '../../../Common/WrappedDivider/WrappedDivider';
import Birthdate from './OrderInfoFields/Birthdate';
import FormikCountrySelect from '../../../Common/Formik/FormikCountrySelect';
import Gender from './OrderInfoFields/Gender';
import PresetCustomQuestion from './OrderInfoFields/PresetCustomQuestion';
import CustomQuestion from './OrderInfoFields/CustomQuestion';
import { validationSchema } from './validationSchema';
import * as inputTypes from '../../../../Consts/inputType';
import Grid from '@mui/material/Unstable_Grid2';
import {
  extraInfo,
  questionOrder,
} from '../../../../Consts/defaultQuestionNames';
const getAddOrderUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/orders`;

const useStyles = makeStyles(() => ({
  name: {
    width: '90%',
  },
}));

const AddOrderManuallyDrawer = ({
  open,
  handleClose,
  goToInvitationDrawer,
}) => {
  const classes = useStyles();
  const [modalOpen, toggleModal] = useState(false);
  const [ticket, setTicket] = useState('');
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [eventInfo] = useContext(Context);
  const { tickets, customQuestions, event } = eventInfo;
  const { eventId, editionId } = event;

  const sortQuestions = (arr) => {
    const presets = arr.filter((p) => p.presetQuestionName);
    presets.sort(
      (a, b) =>
        questionOrder.indexOf(a.presetQuestionName) -
        questionOrder.indexOf(b.presetQuestionName)
    );
    return [...presets, ...arr.filter((q) => !q.presetQuestionName)];
  };

  const questionsForAll = sortQuestions(
    customQuestions.filter(
      (q) =>
        q.appliedTickets?.length === 0 && q.type !== inputTypes.file_download
    )
  );
  const [appliedTicketQuestions, setAppliedTicketQuestions] =
    useState(questionsForAll);

  useEffect(() => {
    const questions =
      customQuestions.filter(
        (q) =>
          q.appliedTickets?.indexOf(ticket) !== -1 &&
          q.type !== inputTypes.file_download
      ) || [];
    const allQuestions = sortQuestions([...questionsForAll, ...questions]);
    setAppliedTicketQuestions(allQuestions);
  }, [ticket]);

  const addOrder = async (values, actions) => {
    const birthDate = new Date(values.birthDate);
    const payload = {
      participants: [
        {
          id: 1,
          deleteParticipant: false,
          orderLines: [{ productId: values.ticketId }],
          ...values,
          birthDate: {
            day: birthDate.getDay(),
            month: birthDate.getMonth(),
            year: birthDate.getFullYear(),
          },
        },
      ],
    };

    try {
      await mutate(
        [orderUrl(eventId, editionId), true],
        async ({ orders }) => {
          const newOrder = await executeApiCall(
            getAddOrderUrl(eventId, editionId),
            methods.post,
            payload
          );
          enqueueSnackbar('Order created successfully', {
            variant: 'success',
          });
          actions.resetForm();
          handleClose();
          const ordersObj = orders.length ? [newOrder, ...orders] : [newOrder];
          return { orders: ordersObj };
        },
        false
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const getExtraInfoObj = () => {
    const ids = flatMapDeep(
      customQuestions.filter((q) => !q.presetQuestionName),
      (q) => q.id
    );
    let extraInfoObj = { ...extraInfo };
    ids.forEach((id) => (extraInfoObj[id] = ''));
    return extraInfoObj;
  };

  return (
    <>
      <Formik
        initialValues={{
          ticketId: '',
          firstName: '',
          lastName: '',
          email: '',
          birthDate: null,
          nationality: '',
          gender: '',
          extraInfo: getExtraInfoObj(),
        }}
        validationSchema={validationSchema}
        onSubmit={addOrder}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          errors,
          values,
          resetForm,
        }) => (
          <DrawerBase
            open={open}
            onClose={() => toggleModal(true)}
            spacing={4}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            submittingMessage="Adding order..."
            submitText="Add order"
            cancelText="Cancel"
          >
            <AddOrderManuallyInfo goToInvitationDrawer={goToInvitationDrawer} />
            <Grid xs={12}>
              <TicketSelect
                setTicket={(id) => {
                  setTicket(id);
                  setFieldValue('ticketId', id);
                }}
                tickets={tickets}
              />
            </Grid>
            <WrappedDivider />
            <Grid xs={12}>
              <Typography variant="body1">Invitee information</Typography>
            </Grid>
            <Grid xs={6}>
              <FormikTextField
                className={classes.name}
                name="firstName"
                label="First name"
                required
              />
            </Grid>
            <Grid xs={6}>
              <FormikTextField
                className={classes.name}
                name="lastName"
                label="Last name"
                required
              />
            </Grid>
            <Grid xs={12}>
              <FormikTextField
                name="email"
                label="Email address"
                fullWidth
                required
                helperText="A confirmation email will be sent to this email address"
              />
            </Grid>
            <Grid xs={6}>
              <Birthdate
                setFieldValue={setFieldValue}
                birthDate={values.birthDate}
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid xs={6}>
              <FormikCountrySelect
                setCountry={(n) => setFieldValue('nationality', n.code)}
                name="nationality"
                label="Nationality"
                country={values.nationality}
              />
            </Grid>
            <Grid xs={12}>
              <Gender setFieldValue={setFieldValue} gender={values.gender} />
            </Grid>
            {appliedTicketQuestions && (
              <QuestionsDisplay
                questions={appliedTicketQuestions}
                setFieldValue={setFieldValue}
                values={values}
              />
            )}
            {modalOpen && (
              <ConfirmationModal
                open={modalOpen}
                cancel={() => toggleModal(false)}
                confirm={() => {
                  toggleModal(false);
                  handleClose();
                  resetForm();
                }}
                title="Are you sure you want to cancel the action?"
                cancelText="No, continue"
                confirmText=" Yes, cancel"
              />
            )}
          </DrawerBase>
        )}
      </Formik>
    </>
  );
};

const QuestionsDisplay = ({ questions, setFieldValue, values }) =>
  questions.map((q) => (
    <React.Fragment key={q.id}>
      {Boolean(q.presetQuestionName) ? (
        <PresetCustomQuestion
          question={q}
          values={values}
          setFieldValue={setFieldValue}
        />
      ) : (
        <Grid xs={12}>
          <CustomQuestion
            values={values}
            setFieldValue={setFieldValue}
            question={q}
          />
        </Grid>
      )}
    </React.Fragment>
  ));

export default AddOrderManuallyDrawer;
