import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useApi, methods } from '../../../../Hooks/useApi';
import { useEdition } from '../../../../Hooks/useEdition';
import { useParams } from 'react-router-dom';
import ItemTable from './ItemTable';
import Grid from '@mui/material/Unstable_Grid2';
import { presetQuestions } from '../../../../Consts/defaultQuestionNames';
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
}));

const updateMappingUrl = (eventId) => `/webhook/mapping/${eventId}`;

const WebhookCustomIDMapping = (props) => {
  const classes = useStyles();
  const { eventId, editionId } = useParams();
  const { edition } = useEdition(eventId, editionId);
  const { tickets, addOns, customQuestions } = edition;
  const [requestInfo, setRequestInfo] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();

  useEffect(() => {
    formInitialValues(tickets, addOns, customQuestions);
  }, [edition]);

  const compareValues = async (values) => {
    let valuesToUpdate = [];
    for (const [key, value] of Object.entries(values)) {
      let editedField = requestInfo.find((item) => item.key === key);
      if (editedField) {
        if (initialValues[key] !== values[key]) {
          //has value changed
          editedField.body.externalId = value;
          if (editedField.hasOwnProperty('valueKey')) {
            // has sub question
            if (
              initialValues[editedField.valueKey] !==
              values[editedField.valueKey]
            ) {
              // sub question value has changed
              editedField.body.externalValueCustomMappingId =
                values[editedField.valueKey];
              valuesToUpdate.push(editedField);
            } else {
              // no subquestion change
              valuesToUpdate.push(editedField);
            }
          } else {
            // no subquestion present
            valuesToUpdate.push(editedField);
          }
        } else if (
          editedField.hasOwnProperty('valueKey') &&
          initialValues[editedField.valueKey] !== values[editedField.valueKey]
        ) {
          // has sub question that has changed but main hasn't changed
          editedField.body.externalValueCustomMappingId =
            values[editedField.valueKey];
          valuesToUpdate.push(editedField);
        }
      }
    }
    return valuesToUpdate;
  };

  /*Iterate tickets, add-ons & custom questions.
   Create initialValues for formik and store requestType for api endpoint*/
  const formInitialValues = (tickets, addOns, customQuestions) => {
    let tempInitialValues = {};
    let tempRequestInfo = [];
    for (let ticket of tickets) {
      tempInitialValues[ticket.id.toString()] = ticket.externalProductId ?? '';
      tempRequestInfo.push({
        key: ticket.id.toString(),
        body: {
          externalId: ticket.externalProductId ?? '',
        },
        productId: ticket.id,

        requestType: 'product',
      });
    }
    for (let addon of addOns) {
      tempInitialValues[addon.id.toString()] = addon.externalProductId ?? '';
      tempRequestInfo.push({
        key: addon.id.toString(),
        body: {
          externalId: addon.externalProductId ?? '',
        },
        productId: addon.id,
        requestType: 'product',
      });
      if (addon.addOnVariant) {
        tempInitialValues[addon.id.toString() + addon.addOnVariant.optionName] =
          addon.addOnVariant.externalOptionId ?? '';
        tempRequestInfo.push({
          key: addon.id.toString() + addon.addOnVariant.optionName,
          body: {
            externalId: addon.addOnVariant.externalOptionId ?? '',
          },
          productId: addon.id,
          requestType: 'productDetails',
        });
        for (let option of addon.addOnVariant.options) {
          let requiredInformationLabel =
            option.requiredInformationLabel?.replace(/[^A-Z0-9]+/gi, '') ?? '';
          tempInitialValues[
            addon.id.toString() + addon.addOnVariant.optionName + option.name
          ] = option.externalValueId ?? '';
          tempInitialValues[
            addon.id.toString() +
              addon.addOnVariant.optionName +
              option.name +
              requiredInformationLabel
          ] = option.externalValueCustomMappingId ?? '';
          tempRequestInfo.push({
            key:
              addon.id.toString() + addon.addOnVariant.optionName + option.name,
            valueKey:
              addon.id.toString() +
              addon.addOnVariant.optionName +
              option.name +
              requiredInformationLabel,
            body: {
              externalId: option.externalValueId ?? '',
              externalValueCustomMappingId: option.externalValueCustomMappingId,
              optionsLabel: option.name,
              wmId: addon.addOnVariant.optionName,
            },
            productId: addon.id,
            requestType: 'productValues',
          });
        }
      }
    }
    for (let customQuestion of customQuestions) {
      tempInitialValues[customQuestion.id.toString()] =
        customQuestion.externalOptionId ?? '';
      tempRequestInfo.push({
        key: customQuestion.id.toString(),
        body: {
          externalId: customQuestion.externalOptionId ?? '',
          id: customQuestion.id,
        },
        requestType: 'customQuestion',
      });
      for (let customQuestionValue of customQuestion.customQuestionValues) {
        if (customQuestionValue.value !== '') {
          tempInitialValues[
            customQuestion.id.toString() + customQuestionValue.id.toString()
          ] = customQuestionValue.externalValueId ?? '';
          tempRequestInfo.push({
            key:
              customQuestion.id.toString() + customQuestionValue.id.toString(),
            body: {
              externalId: customQuestionValue.externalValueId ?? '',
              id: customQuestionValue.id,
            },
            requestType: 'customQuestionValue',
          });
        }
      }
      for (let subQuestion of customQuestion.subQuestions) {
        tempInitialValues[
          customQuestion.id.toString() + subQuestion.id.toString()
        ] = subQuestion.externalOptionId ?? '';
        tempRequestInfo.push({
          key: customQuestion.id.toString() + subQuestion.id.toString(),
          body: {
            externalId: subQuestion.externalOptionId ?? '',
            id: subQuestion.id,
          },
          requestType: 'customQuestion',
        });
        for (let customQuestionValue of subQuestion.customQuestionValues) {
          if (customQuestionValue.value !== '') {
            tempInitialValues[
              customQuestion.id.toString() +
                subQuestion.id.toString() +
                customQuestionValue.id.toString()
            ] = customQuestionValue.externalValueId ?? '';
            tempRequestInfo.push({
              key:
                customQuestion.id.toString() +
                subQuestion.id.toString() +
                customQuestionValue.id.toString(),
              body: {
                externalId: customQuestionValue.externalValueId ?? '',
                id: customQuestionValue.id,
              },
              requestType: 'customQuestionValue',
            });
          }
        }
      }
    }
    delete tempInitialValues['']; //Remove values with ''
    setInitialValues(tempInitialValues);
    setRequestInfo(tempRequestInfo);
  };

  const updateCustomIdMapping = async (valuesToUpdate) => {
    try {
      await executeApiCall(updateMappingUrl(eventId), methods.put, {
        customIdMappingRequests: valuesToUpdate,
      });
      enqueueSnackbar('Custom ID mapping is updated', { variant: 'success' });
    } catch (error) {
      console.log(error);
    }
  };

  const submitValues = async (values) => {
    let valuesToUpdate = await compareValues(values);
    await updateCustomIdMapping(valuesToUpdate);
    setInitialValues(values);
  };

  const orderQuestions = (questions) => {
    let customQuestions = questions.filter((q) => !q.presetQuestionName);
    let presetQuestionsMerged = [];
    let presetQuestionsFiltered = presetQuestions.filter((pq) => {
      return questions.some((q) => {
        return q.presetQuestionName === pq.presetQuestionName;
      });
    });
    for (let i = 0; i < presetQuestionsFiltered.length; i++) {
      presetQuestionsMerged.push({
        ...presetQuestionsFiltered[i],
        ...questions.find(
          (itmInner) =>
            itmInner.presetQuestionName ===
            presetQuestionsFiltered[i].presetQuestionName
        ),
      });
    }
    const presetQuestionsEdited = presetQuestionsMerged.map((obj) => {
      if (obj.presetQuestionName === 'ice' && obj.customQuestionValues.length) {
        var customQuestionValue = obj.customQuestionValues[0];
        return {
          ...obj,
          customQuestionValues: [
            { ...customQuestionValue, value: 'Relationship' },
          ],
        };
      }

      return obj;
    });
    return presetQuestionsEdited.concat(customQuestions);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Box className={classes.content}>
          <Typography variant="body1">
            Use the mapping table to assign the custom IDs you would like to use
            to refer to the product/information in your own system. Custom IDs
            will be sent together with our original ID.
          </Typography>
        </Box>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            await submitValues(values);
            resetForm({ values });
          }}
        >
          {({ handleSubmit, isSubmitting, dirty }) => (
            <>
              <Box className={classes.content}>
                <ItemTable category="Ticket" data={tickets} />
              </Box>
              <Box className={classes.content}>
                <ItemTable category="Add-on" data={addOns} />
              </Box>
              <Box className={classes.content}>
                <ItemTable
                  category="Registration form question"
                  data={orderQuestions(customQuestions)}
                />
              </Box>
              <Grid
                container
                className={classes.buttonsGrid}
                justifyContent="flex-end"
                spacing={0}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting}
                >
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default WebhookCustomIDMapping;
