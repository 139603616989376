import React, { useState, useEffect } from 'react';
import { Card, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Chart } from 'react-google-charts';
import { wmPalette } from '../../Theme/WorldSportsGroupTheme';
import {
  Language as LanguageIcon,
  Cake as CakeIcon,
} from '@mui/icons-material';
import WrappedDivider from '../Common/WrappedDivider/WrappedDivider';
import { countries } from '../../Consts/countries';
import { chunk } from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useApi, methods } from '../../Hooks/useApi';
import NationalitiesModal from './NationalitiesModal';
import Nationalities from './Nationalities';
import { mapToSuitableFormat } from './geoChartUtils';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  participantCard: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  marginTop: {
    marginBottom: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  marginTB: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginRight: {
    paddingRight: '4em',
  },
  icon: {
    color: wmPalette.green[70],
    height: '50px',
    width: '50px',
    marginRight: theme.spacing(2),
  },
  geoChart: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(0),
    },
  },
  ageDistribution: {
    width: '80%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  genderSplit: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  },
  GenderSplitTypo: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
    },
  },
  showAll: {
    color: wmPalette.blue[100],
    cursor: 'pointer',
    marginTop: theme.spacing(4),
    fontWeight: 'bold',
  },
  nationalitiesBox: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  paddingZero: {
    padding: 0,
  },
  paddingSixteen: {
    padding: theme.spacing(2),
  },
}));

const dataURL = (eventId, editionId, endpoint, endDate, saleStartDay) =>
  `/eventstats/${eventId}/races/${editionId}/orders/${endpoint}?from=${saleStartDay}&to=${endDate}&currency=EUR`;

const statisticsURL = (eventId, editionId) =>
  `/statistics/${eventId}/${editionId}/average-age`;

const getCountryCode = (country, listValues, fromAPI) => {
  if (fromAPI) {
    const value = listValues.filter((obj) => {
      return obj.name === country.label;
    });
    if (value.length > 0) return value[0].code;
  }
  const value = listValues.filter((obj) => {
    return obj.label === country.label;
  });
  return value.length === 0 ? 'XX' : value[0].code;
};

const getShare = (base, partial) => {
  return ((100 / partial) * base).toFixed(0);
};

const ParticipantData = ({
  eventId,
  editionId,
  eventInfo,
  underReview,
  startDate,
  endDate,
  hasNonbinaryGenderQuestion,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { executeApiCall } = useApi();
  const [geoChartData, setgeoChartData] = useState([]);
  const [geoListData, setGeoListData] = useState([]);
  const [modalListData, setModalListData] = useState([]);
  const [uniqueNationalities, setUniqueNationalities] = useState(0);
  const [averageAge, setAverageAge] = useState(0);
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [genderSplit, setGenderSplit] = useState([
    ['', ''],
    ['Male', 0],
    ['Female', 0.4],
    ['Nonbinary', 0],
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const fetchNationalities = async () => {
    try {
      const data = await executeApiCall(
        dataURL(
          eventId,
          editionId,
          'participantnationalities',
          endDate,
          startDate
        ),
        methods.get
      );
      let listValues =
        data.countryCodes.length === 0
          ? Object.values(countries)
          : data.countryCodes;
      const countryCodesComesFomAPI = data.countryCodes.length > 0;
      let listData = [];
      data.list.forEach((n, i) => {
        listData.push({
          id: i + 1,
          label: n.label,
          val: n.val,
          code: getCountryCode(n, listValues, countryCodesComesFomAPI),
          share: getShare(n.val, data.chart.series[0].total),
        });
      });

      const listDataChunked5 = chunk(listData, 5).slice(0, 2);
      const listDataChunked2 = chunk(listData, Math.ceil(listData.length) / 2);
      setGeoListData(listDataChunked5);
      setModalListData(listDataChunked2);

      const geoChartNationalities = data.list.map(Object.values);
      setUniqueNationalities(geoChartNationalities.length);
      geoChartNationalities.unshift(['Country', 'Participants']);
      setgeoChartData(mapToSuitableFormat(geoChartNationalities));
    } catch (e) {}
  };

  const fetchAgeDistribution = async () => {
    try {
      const data = await executeApiCall(
        dataURL(eventId, editionId, 'participantsperage', endDate, startDate),
        methods.get
      );

      const total = data.series
        .map((grp) => {
          return grp.total;
        })
        .reduce((a, b) => a + b, 0);

      const values = [];
      data.series.forEach((group) => {
        values.push([
          group.name,
          group.total,
          `${group.total} (${getShare(group.total, total)}%)`,
        ]);
      });
      if (values.length === 0) {
        values.push(
          ['0-19', 0, ''],
          ['20-29', 0, '-'],
          ['20-29', 0, '-'],
          ['30-39', 0, '-'],
          ['40-49', 0, '-'],
          ['50-59', 0, '-'],
          ['60-69', 0, '-'],
          ['70+', 0, '-']
        );
      }
      values.unshift(['Group', 'Share', { role: 'annotation' }]);
      setAgeDistribution(values);
    } catch (e) {}
  };

  const fetchGenderSplit = async () => {
    try {
      const data = await executeApiCall(
        dataURL(
          eventId,
          editionId,
          'participantgenderbalance',
          endDate,
          startDate
        ),
        methods.get
      );
      let values = [];
      data.series[0].values.forEach((gender) => {
        values.push([gender.label, gender.val]);
      });

      getShare(values[[0][1]]);
      if (values[0][1] === 0 && values[1][1] === 0 && values[2][1] === 0) {
        values[0][1] = 0.4;
        values[1][1] = 0.4;
      }
      values.unshift(['', '']);
      setGenderSplit(values);
    } catch (e) {}
  };

  const fetchAverageAge = async () => {
    try {
      const data = await executeApiCall(
        statisticsURL(eventId, editionId),
        methods.get
      );
      setAverageAge(data.toFixed(0));
    } catch (e) {}
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchNationalities();
      await fetchAgeDistribution();
      await fetchGenderSplit();
      await fetchAverageAge();
    };
    fetchAllData();
  }, [startDate]);

  return (
    <>
      <Card className={classes.participantCard}>
        <Grid
          container
          direction="column"
          spacing={0}
          className={classes.paddingZero}
        >
          <Grid xs={12}>
            <Typography className={classes.marginBottom} variant="h5">
              Participant Data
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={4}
            className={classes.paddingZero}
          >
            <Grid
              container
              xs={12}
              md={6}
              lg={4}
              direction="row"
              spacing={0}
              className={classes.paddingSixteen}
            >
              <LanguageIcon className={classes.icon} />
              <Grid>
                <Grid
                  container
                  xsdirection="column"
                  className={classes.paddingZero}
                ></Grid>
                <Typography variant="h4">
                  {underReview ? '-' : uniqueNationalities}
                </Typography>
                <Typography variant="body1">Nationalities</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={6}
              lg={4}
              direction="row"
              className={clsx(classes.marginBottom, classes.paddingSixteen)}
              spacing={0}
            >
              <CakeIcon className={classes.icon} />
              <Grid>
                <Grid
                  container
                  direction="column"
                  spacing={0}
                  className={classes.paddingZero}
                ></Grid>
                <Typography variant="h4">
                  {underReview ? '-' : averageAge}
                </Typography>
                <Typography variant="body1">Average age</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <WrappedDivider className={classes.padding} />
        <Box className={classes.geoChart}>
          <Chart
            width={'100%'}
            height={isMobile ? '' : '400px'}
            chartType="GeoChart"
            data={geoChartData}
            rootProps={{ 'data-testid': '1' }}
            options={{
              legend: 'none',
              chartArea: { width: '100%', height: '100%' },
              colorAxis: { colors: [wmPalette.grey[20], wmPalette.green[70]] },
            }}
          />
        </Box>
        <Box className={classes.nationalitiesBox}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={0}
          >
            <Nationalities nationalities={geoListData} />
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" spacing={0}>
            <Typography
              className={classes.showAll}
              onClick={() => setOpenModal(true)}
            >
              SHOW ALL
            </Typography>
          </Grid>
        </Box>
        <WrappedDivider />
        <Grid container spacing={0}>
          <Grid xs={12} md={6}>
            <Typography className={classes.marginTB} variant="h5">
              Age distribution
            </Typography>
            <Box className={classes.ageDistribution}>
              <Chart
                width="100%"
                height={'300px'}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={ageDistribution}
                options={{
                  axes: {
                    x: {
                      0: { side: 'top' },
                    },
                  },
                  xAxis: {
                    side: 'top',
                  },
                  hAxis: {
                    minValue: 0,
                    side: 'top',
                  },
                  vAxis: {},
                  colors: [wmPalette.green[70]],
                  legend: 'none',
                  chartArea: { height: '100%', width: '100%', left: '40' },
                }}
              />
            </Box>
          </Grid>
          <Grid
            container
            xs={12}
            md={6}
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            spacing={0}
          >
            <Typography
              className={clsx(classes.marginTB, classes.GenderSplitTypo)}
              variant="h5"
            >
              Gender split
            </Typography>
            <Box className={classes.genderSplit}>
              <Chart
                width={'100%'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={genderSplit}
                options={{
                  pieHole: 0.6,
                  legend: 'none',
                  chartArea: `${
                    isMobile ? 'width: 50%' : 'width: 100%'
                  }, height: 250px, top: '0'`,
                  pieSliceText: 'none',
                  tooltip: { trigger: 'none' },
                  enableInteractivity: false,
                  slices: {
                    0: {
                      color:
                        genderSplit[1][1] === 0.4
                          ? wmPalette.black[20]
                          : wmPalette.green[70],
                    },
                    1: {
                      color:
                        genderSplit[2][1] === 0.4
                          ? wmPalette.black[20]
                          : wmPalette.purple[90],
                    },
                    2: {
                      color:
                        genderSplit[3][1] === 0.4
                          ? wmPalette.black[20]
                          : wmPalette.orange[70],
                    },
                  },
                }}
                rootProps={{ 'data-testid': '3' }}
              />
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Grid>
                  <Typography variant="h6">
                    Male{' '}
                    {genderSplit[1][1] !== 0.4
                      ? `${genderSplit[1][1].toFixed(0)} (${getShare(
                          genderSplit[1][1],
                          genderSplit[1][1] +
                            genderSplit[2][1] +
                            genderSplit[3][1]
                        )}%)`
                      : '-'}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="h6">
                    Female{' '}
                    {genderSplit[2][1] !== 0.4
                      ? `${genderSplit[2][1].toFixed(0)} (${getShare(
                          genderSplit[2][1],
                          genderSplit[1][1] +
                            genderSplit[2][1] +
                            genderSplit[3][1]
                        )}%)`
                      : '-'}
                  </Typography>
                </Grid>
                {(hasNonbinaryGenderQuestion || genderSplit[3][1] > 0) && (
                  <Grid>
                    <Typography variant="h6">
                      Nonbinary{' '}
                      {genderSplit[3][1] !== 0.4
                        ? `${genderSplit[3][1].toFixed(0)} (${getShare(
                            genderSplit[3][1],
                            genderSplit[1][1] +
                              genderSplit[2][1] +
                              genderSplit[3][1]
                          )}%)`
                        : '-'}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <NationalitiesModal
          open={openModal}
          handleClose={setOpenModal}
          nationalities={modalListData}
          uniqueNationalities={uniqueNationalities}
        />
      </Card>
    </>
  );
};

export default ParticipantData;
